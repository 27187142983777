import _ from 'lodash';

const CLOUDINARY_3D_TYPES =['bw', 'fbx', 'glb', 'gltf', 'obj', 'ply', 'u3ma','usdz'];
const MOBILE_OS_FLAVORS_WITH_MODEL_SUPPORT = ['IOS', 'ANDROID'];

const DERIVED_ASSETS_URL_FACTORY = {
    image: (asset) => _.get(asset, 'derived', []).find((asset) => asset.format === 'jpg' || asset.format === 'jpeg') ? _.get(asset, 'derived', []).find((asset) => asset.format === 'jpg' || asset.format === 'jpeg') : asset.format === 'jpg' || asset.format === 'jpeg' ? asset : null,
    gif: (asset) => _.get(asset, 'derived', []).find((asset) => asset.format === 'gif') ? _.get(asset, 'derived', []).find((asset) => asset.format === 'gif') : asset.format === 'gif' ? asset : null,
    video: (asset) => _.get(asset, 'derived', []).find((asset) => asset.format === 'mp4') ? _.get(asset, 'mp4', []).find((asset) => asset.format === 'mp4') : asset.format === 'mp4' ? asset : null,
    videoGif: (asset) => _.get(asset, 'derived', []).find((asset) => asset.format === 'gif'),
    modelThumbGif: (asset) => _.get(asset, 'derived', []).find((asset) => asset.format === 'gif'),
    usdz: (asset) => _.get(asset, 'derived', []).find((asset) => asset.format === 'usdz'),
    glb: (asset) => asset.format === 'glb' ? asset :  _.get(asset, 'derived', []).find((asset) => asset.format === 'glb') ,
};

const SHARE_API_HOST = process.env.SHARE_API_HOST || 'https://api.share.nikesportpulse.com/api/';

export {
    SHARE_API_HOST,
    CLOUDINARY_3D_TYPES,
    MOBILE_OS_FLAVORS_WITH_MODEL_SUPPORT,
    DERIVED_ASSETS_URL_FACTORY
};