@import '../../../vars.scss';

.errorPage {
    @include pageStyles;
    text-align: center;
}

@keyframes pulse {
    50% {
      opacity: 0;
    }
  }

