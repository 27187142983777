import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Assets from './screens/assets';
import Home from './screens/home';
import Error from './screens/error';

const App = () => (
    <div id='mainapp'>
        <Routes>
            <Route path={'error'} element={<Error />} />
            <Route path={'assets/folder/:cloudinaryFolder/:folderId'} element={<Assets />} />
            <Route path={'/'} element={<Home />} />
            <Route
                path="*"
                element={<Navigate to="error?message=Page Not Found." replace />}
            />
        </Routes>
    </div>
);

export default App; 