import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Header } from '../../components';
import * as classes from './style.module.scss';

const Error = () => {
    const [searchParams] = useSearchParams();
    const message = searchParams.get('message');

    return (
        <div className={classes.errorPage}>
            <Header loaded={true}/>
            <div className={classes.contentContainer}>
                <div className={classes.error}>SOMETHING WENT WRONG: <br/>{message.toUpperCase()}<br/>PLEASE SEE A DESIGN CAFE TECHNICIAN.</div>
            </div>
        </div>
    );
};

export default Error;