@import '../../../vars.scss';

.assets {
    @include pageStyles;
    .contentContainer {
        .assetsImage {
            @include mediaContainer;
            > img {
                width: 100%;
                height: 100%
            }
        }
    }
}

