@import '../../../vars.scss';

.home {
    @include pageStyles;
    .contentContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

}

.swoosh {
    width: $masterWidth;
    object-fit: contain;
    > img {
        width: 100%;
    }
}