import React from 'react';

import * as classes from './style.module.scss';

const Home = () => {
    const swooshUrl = new URL('/assets/images/swoosh.svg', import.meta.url);
    return (
        <div className={classes.home}>
            <div className={classes.contentContainer}>
                <div className={classes.swoosh}>
                    <img src={swooshUrl} alt=''/>
                </div>
            </div>
        </div>
    );
};

export default Home;