@import '../../../vars.scss';

@mixin swoosh {
    position: absolute;
    top: 30%;
    right: 3.8%;
}

.header {
    width: 100vw;    
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @include responsiveSizing;

    .headerImage {
        @include responsiveSizing;
    }
}